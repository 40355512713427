.container {
    display: flex;
    min-height: 100vh;
    height: auto;

  }
  
  .sidebar {
    background: linear-gradient(to right, #243e70, #253553);
    transition: width 0.5s ease-in-out;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    height: 100%;
  }
  
  .sidebar.expanded {
    width: 20%;

  }
  
  .sidebar.collapsed {
    width: 10%;
  }
  
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .profile-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .tab-button {
    background-color: #EAF3F8;
    color: #333;
    border: none;
    padding: 15px;
    width: 100%;
    margin: 8px 0;
    cursor: pointer;
    border-radius: 5px;
    text-align: left;
  }
  
  .tab-button:hover {
    background-color: #ddd;
  }
  
  .tab-button.active {
    background-color: #5287EF;
    color: white;
  }
  
  .toggle-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    font-size: 20px;
    transition: transform 0.5s ease-in-out;
    color: white;
    height: 80px;
    width: 25px;
    font-weight: bold;
    padding-right: 10px;
  }

  .profile-section{
    height: 8rem;
  }

  .profile-circle{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #f8feff;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .username {
    font-weight: bold;
    font-size: 30px;
    color: white;
    position: relative;
    bottom: 1.5rem;
  }

 .edit-profile {
    color: rgb(253, 253, 253);
    position: relative;
    bottom: 3.5rem;
  }
  
  .logout-button {
    background-color: #F16B70;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto;
    width: 70%;
    margin: 0 auto;
  }
  .Student-result{
    flex: 1;
  }
  
  .render-active {
    transition: margin-left 0.3s ease;
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
  }
  
  .render-active.expanded {
    margin-left: 20%;
  }
  
  .render-active.collapsed {
    margin-left: 40%;
  }

.tests-available {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f6f8ff;
    height: 100vh;
  }
  

  .test-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    margin-top: 20px;
  }
  

  .test-card {
    width: 240px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .section-title {
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 400; 
    text-align: center;
    color: #294478;
  }
  
  
  .test-card-img {
    width: 100px;
    border-radius: 8px;
  }
  
  .test-button {
    background-color: #1FCC7D;
    color: white;
    border: none;
    padding: 8px;
    width: 80%;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
  }
  

  .test-res-button {
    background-color: #426CBF;
    color: white;
    border: none;
    padding: 8px;
    width: 80%;
    margin: 5px 0;
    border-radius: 6px;
    cursor: pointer;
  }

  .read-more {
    color:#375ca2;
    cursor: pointer;
    text-decoration: none;
  }
  
  .test-card p {
    font-size: 14px;
    color: #333;
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .container {
        flex-direction: column;
      }

    .sidebar {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      padding: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .sidebar.expanded {
        width: 100%;
      }
  
    .tests-available {
      width: 100%;
      padding: 10px;
      height: auto;
    }
  

    .render-active.expanded {
      margin-left: 0;
    }
    
    .render-active.collapsed {
      margin-left: 0;
    }

    .test-cards {
      justify-content: center;
      gap: 30px;
    }

    .test-card {
        width: 75%;
      }
    

    .profile-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
      }
      
      .profile-icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      
      .tabs {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: center;
        width: 95%;
        margin-bottom: 20px;
        padding: 10px;
      }
      
      .tab-button {
        border-bottom: 1px solid #EAF3F8;
        background: none;
        color: #ffffff;
        padding: 5px;
        width: 80%;
        height: 3rem;
        margin: 10px 0;
        cursor: pointer;
        border-radius: 5px;
        font-size: 10px;
        text-align: left;
      }
      
      
      .tab-button.active {
        background-color: #5287EF;
        color: white;
      }

      .tests-available {
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;

      }

      .toggle-button {
        display: none;
      }
  }
  