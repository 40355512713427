.question-card {
  width: 60%;
  height: 40vh;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #7e6d6d;
  margin-bottom: 1rem;
}

.question {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.answer-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.checkbox {
  margin-bottom: 0.5rem;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  cursor: pointer;
}

.checkbox.large {
  width: 30px;
  height: 30px;
}

.checkbox.medium {
  width: 25px;
  height: 25px;
}

.checkbox.small {
  width: 20px;
  height: 20px;
}

.checkbox.green {
  border-color: #4caf50; /* Green border for positive answers */
}

.checkbox.yellow {
  border-color: #ffeb3b; /* Yellow border for neutral answer */
}

.checkbox.red {
  border-color: #f44336; /* Red border for negative answers */
}

@media (max-width: 768px) {
  .question-card {
    width: 90%;
    height: auto;
  }

  .question {
    font-size: 1rem;
  }

  .checkbox.large {
    width: 25px;
    height: 25px;
  }

  .checkbox.medium {
    width: 20px;
    height: 20px;
  }

  .checkbox.small {
    width: 15px;
    height: 15px;
  }
}
