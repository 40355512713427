@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Stick&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Sacramento&family=Stick&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.main-container {
  display: flex;
  height: 100vh;
  font-family: "Outfit", sans-serif;
  background-image: repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%));
}

h1, h2, h3, h4, h5, h6 ,p, label, a, button, input {
  font-family: "Outfit", sans-serif;
}


.ai-download-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
  gap: 2rem;
}


/* .ai-feature{
  background-color: antiquewhite;
  width: 20%;
} */
.ai-btn{
  width: 100px;
  height: 35px;
  border-radius: 2px;
  border: none;
  background-color: #d9d9d9;
  cursor: pointer;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
}

.ai-btn:hover{
  color: white;
}

.ai-feature{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .side-bar {
    width: 40%;
  }

  .ai-download-btn{
    width: 100%;
  }


  .buttons-container {
    padding: 20px;
    gap: 15px;

  }
  
  .button {
    margin-bottom: 10px;
    padding: 10px;
    width: 90%;
    text-decoration: none;
    color: #333;
    background-color: #ccc;
    border: 1px solid #999;
    border-radius: 5px;
    font-size: 13px;
  }
}