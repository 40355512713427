/* Login.css */

.outer-login-container {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(to left, #253c67, #31508F);
  height: 100vh;
  display: flex;
  opacity: 1;
  transition: opacity 2s ease;
}

.reg-side{
  overflow-y: auto;
    overflow: hidden;
}

.left-section{
  display: flex;
  flex-direction: column;
}

.register-mode .left-section {
  order: 2;
}

.register-mode .right-outer-section {
  order: 1;
  flex-direction: row-reverse;

}

.register-mode .other-section {
  order: -1;
  display: none;
}

.fade-in {
  opacity: 1;
  transition: opacity 2s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 2s ease;
}

.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease;
  overflow: hidden; 
}

.left-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.5s ease;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.login-image {
  width: 100%;
  height: 85%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-outer-section {
  flex: 0.6;
  display: flex;
  overflow-y: auto; 
}

.other-section {
  flex: 0.2;
}

.right-section {
  background-color: #F0F5F7;
  padding: 10px;
  width: 60%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.login-form {
  width: 90%;
  scrollbar-width: none;
  overflow-y: auto;
}

.login-form::-webkit-scrollbar {
  width: 0;
  display: none;
}

.login-form h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #294478;;
  font-weight: 200;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-container label {
  margin-bottom: 5px;
  font-size: 14px;
}

.input-container input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 92%;
  background-color: #EAF3F8;
}

input::placeholder {
  color: #93b0c1;
  opacity: 1;
}

.password-wrapper {
  position: relative;
}


.toggle-password {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.2rem;
  color: #93adbb;
}

 .spinner {
  border: 4px solid rgba(228, 228, 228, 0.1);
  border-radius: 50%;
  border-top: 3px solid #bec0c3; /* Adjust color to match your theme */
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.login-button {
  background-color: rgb(4, 151, 4);
  color: white;
  padding: 10px;
  width: 97%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
}

.login-button:hover {
  background-color: rgb(0, 128, 0);
}


.login-button:disabled {
  background-color: #93b0c1;
  cursor: not-allowed;
}

.noregtag {
  margin-top: 10px;
  font-size: 16px;
}

.noreg {
  color: #426CBF;
  text-decoration: none;
  cursor: pointer;
}

.divider {
  margin: 15px 0;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
}

.social-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-button {
  background-color: #F0F5F7;
  color: rgb(52, 52, 52);
  border: 2px solid #393939;
  border-radius: 10px;
}

.google-button:hover {
  background-color: #EAF3F8;
}

.apple-button {
  background-color: #F0F5F7;
  color: rgb(27, 27, 27);
  border: 2px solid #393939;
  border-radius: 10px;
}

.apple-button:hover {
  background-color: #EAF3F8;
}

.kaeval-logo-image {
  width: 7rem;
  height: 7rem;
  margin: 0 auto;
  margin-top: 10px;
}

.kaeval-logo-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bottom-logo {
  margin-top: 20px;
  width: 50px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.agreement-section{
  display: flex;
  gap: 5px;
  padding: 10px;
}

.agreementp{
  font-size: 16px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .left-section {
    display: none; /* Hide the image section on mobile devices */
  }

  .right-outer-section {
    flex: 1;
    display: flex;
    max-width: 100%;
  }

  .right-section {
    flex: 1; /* Make the right section take the full width */
    max-width: 100%;
    padding: 20px; /* Add padding to keep the content away from the edges */
  }

  .other-section {
    width: 1px;
  }

  .login-form {
    width: 100%;
  }

  .login-form h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #426CBF;
  }


  .agreementp{
    font-size: 14px;
  }
}
