

.tTemperament-question-container {
    margin-bottom: 20px;
    background-color: #d1c398;
    background-color: #DCE7FC;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  .tpersonality-question-container {
    margin-bottom: 20px;
    background-color: #a1cbd6;
    background-color: #DCE7FC;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  .heading{
    color: #313131;
    margin-bottom: 20px;
  }
  
  .btn1, .btn2{
      width: 100px;
      height: 35px;
      border-radius: 10px;
      border: none;
      background-color: #1a788d;
      cursor: pointer;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .btn1:hover, .btn2:hover{
      background-color: #155e6e;
  }
  
  .btn1:active, .btn2:active{
      background-color: #0f4a5a;
  }
  
  

.student-temperament-rep{
    width: 80%;
    padding: 20px;
    margin: 0 auto;
}


.student-temperament-rep h3{
    color: #1a788d;
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
}

.student-temperament-rep h3:hover{
    color: #155e6e;
}


.result-heading{
    border-bottom: #c1c1c1 1px solid;
    cursor: pointer;
}

.result-heading:hover{
    background-color: #5f7ab1;
    color: #fff;
}

.temp-btn{
    width: 100px;
    height: 35px;
    border-radius: 2px;
    border: none;
    background-color: #1a788d;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}

.dwlbtn{
    width: 100px;
    height: 35px;
    border-radius: 2px;
    border: none;
    background-color: #1a788d;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}