@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Stick&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Edu+NSW+ACT+Foundation:wght@400..700&family=Great+Vibes&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Sacramento&family=Stick&display=swap');


.ttests-container {
  font-family: "Montserrat Alternates", sans-serif;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-image: repeating-linear-gradient(45deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),repeating-linear-gradient(135deg, rgb(255,255,255) 0px, rgb(255,255,255) 10px,transparent 10px, transparent 11px),linear-gradient(90deg, hsl(256,7%,84%),hsl(256,7%,84%));
}

.raisec-question-container {
  margin-bottom: 20px;
  background-color: #DCE7FC;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.raisec-question-container h3 {
  font-size: 25px;
}

.raisec-question-container p {
  font-size: 20px;
}


/* CSS for square radio input with inner shadow */
input[type="radio"].square {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc; /* Border color */
  border-radius: 5px; /* Adjust the border radius to make it square */
  background-color: white; /* Background color */
  outline: none;
}

/* Inner shadow effect */
input[type="radio"].square:checked {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Inner shadow color and size */
  background-color: #0056b3;
}


.tanswer-options {
  display: flex;
  flex-direction: column;
}

.tanswer-option {
  margin-bottom: 5px;
}

.submit-button {
  background-color: #000000;
  color: #fff;
  padding: 15px 20px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.results-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}


.lodder{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
}

.results-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    margin-top: 30px;
}

.result-profile-img img{
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);

}


.results-rep{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(89, 89, 89, 0.3);
  width: 24%;
  min-height: 60vh;
}

.results-rep p{
  font-size: 16px;
  color: #1c7287;
}


.results-upper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #1c7287;
  color: #fff;
  min-height: 8vh;

}

.results-lower{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #c3d7ff;
  color: #fff;
  flex: 1;


}


.result-heading{
  background-color: #c3d7ff;
  color: #000000;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem; 
}

.pagination{
 padding: 10px;

}

.pagination button{
  margin-right: 20px;
}

.student-career-list{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  

}

.student-career-rep{
  width: 80%;

}
.return{
  width: 100%;
  display: flex;
  align-items: center;

}

.back-btn{
  background-color: #1c7287;
  color: #fff;
  padding: 15px 20px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.career-heading{
  background-color: #A9C3F7;
  color: #000000;
  display: flex;
  padding: 10px;
}


.nex-button {
  background-color: #5274b7;
  color: #fff;
  padding: 15px 20px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
}

.nex-button:hover {
  background-color: #3f6b9a;
}


@media (max-width: 768px) {
        .ttests-container {
          font-family: "Outfit", sans-serif;
          width: 90%;
        }


    .results-rep{
        flex-direction: column;
        width: 90%;

      }

      .results-section{
        width: 99%;
      }
      
    }
  