/* styles.css or a CSS module */
.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    text-align: center;
    padding: 20px;
    color: #16425b;
  }
  
  .large-404 {
    font-size: 100px;
    margin: 0;
    font-weight: bold;
    color: #16425b;
  }
  
  .not-found p {
    font-size: 20px;
    margin: 20px 0;
    color: #333;
  }
  
  .package-image {
    width: 150px; /* Adjust the size as needed */
    margin: 20px 0;
  }
  
  .back-home-btn {
    padding: 10px 20px;
    background-color: #16425b;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .back-home-btn:hover {
    background-color: black;
    color: white;
  }
  